import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "components/Layout/de"

import partnershipImg from "./partnershipImg.jpg"
import droneImg from "./droneImg.jpeg"
import hardwareImg from "./hardwareImg.jpg"
import BasicPartnerLogo from "./Basic.png"
import GoldPartnerLogo from "./Gold.png"
import Image from "components/Image/index"

export default function(props) {
  const header = {
    x: 44,
    y: 33,
    text: "Gemeinsam mehr erreichen",
    description:
      "Haben Sie eigene Ideen oder sind Sie Experte aus einer anderen Industrie? Profitieren Sie von der Partnerschaft mit MoonVision.",
    image: partnershipImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="py-5 text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <h2>
              Gemeinsam schaffen wir neue Lösungen für die digitale Zukunft
            </h2>
            <p>
              Sie möchten Ihre Produkte und Angebote um Computer Vision KI
              (künstliche Intelligenz) erweitern? Dann werden Sie unser Partner
              und profitieren Sie von unserer jahrelangen Erfahrung und von
              unserem Innovationsgeist! Gemeinsam können wir mit visuellen Daten
              neue Bereiche erobern, Innovationen vorantreiben und neue
              Geschäftsmodelle entwickeln.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="7">
            <div className="grey-background p-2">
              <h2>Hardware-Partner werden</h2>
              <p>Gemeinsam bieten wir neue Bereiche</p>
              <h3>Vorteile einer Hardware-Partnerschaft</h3>
              <p>
                Als Hardware-Partner, wie z.B. Anlagenbauer, Mikroskop- oder
                Kamera-Hersteller eröffnen wir Ihnen durch Integration der
                MoonVision Bildverarbeitungs-Software unter Anwendung von KI/AI
                die Möglichkeit, sich als Gesamtlösungsanbieter bei Ihren Kunden
                zu präsentieren. Die künstliche Intelligenz kann Aufgaben
                effizienter und fehlerfreier durchführen, als dies bislang
                möglich war, wodurch Automatisierung, manuelle
                Aufwandsreduzierung und verbessertes Reporting möglich sind.
              </p>
              <ul>
                <li>Zugang zum Kundennetzwerk von MoonVision</li>
                <li>
                  Steigerung Ihrer Vertriebsmöglichkeiten als Partner von
                  MoonVision
                </li>
                <li>
                  Bieten Sie Ihren Kunden eine Gesamtlösung aus Hard- und
                  Software an
                </li>
              </ul>
              <Button
                onClick={() => {
                  window.$crisp && window.$crisp.push(["do", "chat:open"])
                }}
              >
                Jetzt Partner werden
              </Button>
            </div>
          </Col>
          <Col md="5">
            <Image filename={hardwareImg} alt="hardware partner" />
          </Col>
        </Row>
        <Row className="pt-5">
          <Col>
            <Image
              filename={BasicPartnerLogo}
              alt="basic partner logo"
              className="my-3"
              width="400"
            />
          </Col>
          <Col>
            <Image
              filename={GoldPartnerLogo}
              alt="gold partner logo"
              className="my-3"
              width="400"
            />
          </Col>
        </Row>
        <Row className="pb-5">
          <Col className="bluebox">
            <p>
              Als Certified.Partner garantieren wir unseren Kunden höchste
              Qualitätsstandards, engen Austausch und eine optimale Harmonie
              zwischen Hardware und Software. Gemeinsam bieten wir höchste
              Betriebsstabilität und definieren unseren Kunden ein neues
              Qualitätslevel.
            </p>

            <Link
              to="de/partnership/certified-partner"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Mehr über Certified.Partner
            </Link>
          </Col>
        </Row>
        <Row className="py-5">
          <Col md="5">
            <img src={droneImg} alt="solution partner" />
          </Col>
          <Col md="7">
            <div className="grey-background p-2">
              <h2>Solution-Partner werden</h2>
              <p>Gemeinsam bieten wir neue Bereiche</p>
              <h3>Vorteile einer Solution-Partnerschaft</h3>
              <p>
                Als Experte, wie z.B. Haushaltsgeräte-Hersteller,
                Landschafts-Planer, Drohnen-Operator oder Mobile-Solution
                Spezialist in der Branche versorgen Sie schon heute Ihre Kunden
                mit spezifischen Lösungen. Nun ist es an der Zeit neue digitale
                Geschäftsmodelle aufzubauen oder Ihre Software um automatisierte
                Prozesse in der Bilderkennung und -verarbeitung unter dem
                Einsatz von KI/AI zu erweitern.
              </p>
              <ul>
                <li>
                  Als Solution-Partner sind Sie weiterhin erster Ansprechpartner
                  für Ihre Kunden
                </li>
                <li>
                  Automatisierter & fehlerfreier Betrieb in der Bildverarbeitung
                  durch MoonVision
                </li>
                <li>
                  Unterstützung bei der Nahtlosen Integration von Schnittstellen
                  zur Schaffung gemeinsamer Lösungen
                </li>
              </ul>
              <Button
                onClick={() => {
                  window.$crisp && window.$crisp.push(["do", "chat:open"])
                }}
              >
                Jetzt Partner werden
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
